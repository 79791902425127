import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

export default function index(props) {
  return (
    <Flex
      bg={props.color}
      rounded='100px'
      p='18px'
      w={{ sm: '60px', md: '60px', lg: '80px', xl: '80px' }}
      h={{ sm: '60px', md: '60px', lg: '80px', xl: '80px' }}
      align='center'
      justify='center'
    >
      <Text color='neutral.25' fontSize='30px' fontWeight='700'>
        <Image src={props.icon} alt='icon' w={{ sm: '30px', md: '30px', lg: '60px', xl: '60px' }} />
      </Text>
    </Flex>
  )
}
