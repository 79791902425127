import { Accordion, Flex, Image } from '@chakra-ui/react'
import { Text as UbiText } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/layout'

import config from '../../config/configLoader'
import faqUsageIcon from '../../assets/faqusage.svg'

import { Faq } from './components/Faq'
import { ValidatorTable } from './components/ValidatorTable'
import { HowToUseFaq } from './components/HowToUseFaq'

export const Faqs = () => {
  const { t } = useTranslation()
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const location = useLocation()

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  if (location.state) {
    timeout(500).then(r => {
      const element = document.getElementById('learnMore')
      if (element) {
        const yOffset = windowSize.current[0] < 960 ? -70 : -120
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    })
  }

  return (
    <Flex bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }} rounded='8px' padding={{ sm: '0px', md: '24px', lg: '24px', xl: '24px' }} direction='column'>
      <UbiText type='displayXS' weight='bold'>
        {t(`faqs_${config.operator}.faqs`)}
      </UbiText>
      <Accordion allowToggle defaultIndex={location.state ? [19] : []} flex='1'>
        <Faq number={'1'} title={t('custom_faqs.how_to_use_system')} description={' '}>
          {windowSize.current[0] < 768 && <HowToUseFaq />}
          {windowSize.current[0] >= 768 && <Image src={faqUsageIcon}></Image>}
        </Faq>
        <Faq number={'2'} title={t(`faqs_${config.operator}.what_is_the_advantage_question`)} description={t(`faqs_${config.operator}.what_is_the_advantage_answer`)} />
        <Faq number={'3'} title={t(`faqs_${config.operator}.where_can_i_travel_question`)} description={t(`faqs_${config.operator}.where_can_i_travel_answer`)} />
        <Faq number={'4'} title={t(`faqs_${config.operator}.who_can_use_system_question`)} description={t(`faqs_${config.operator}.who_can_use_system_answer`)} />
        <Faq number={'5'} title={t(`faqs_${config.operator}.how_much_each_trip_costs_question`)} description={t(`faqs_${config.operator}.how_much_each_trip_costs_answer`)} />
        <Faq number={'6'} title={t(`faqs_${config.operator}.what_is_validity_trip_question`)} description={t(`faqs_${config.operator}.what_is_validity_trip_answer`)} />
        <Faq number={'7'} title={t(`faqs_${config.operator}.how_can_i_validate_question`)} description={t(`faqs_${config.operator}.how_can_i_validate_answer`)} />
        <Faq
          number={'8'}
          title={t(`faqs_${config.operator}.apple_pay_google_pay_available_question`)}
          description={t(`faqs_${config.operator}.apple_pay_google_pay_available_answer`)}
        />
        <Faq
          number={'9'}
          title={t(`faqs_${config.operator}.can_i_use_same_card_on_same_day_question`)}
          description={t(`faqs_${config.operator}.can_i_use_same_card_on_same_day_answer`)}
        />
        <Faq
          number={'10'}
          title={t(`faqs_${config.operator}.how_can_i_know_if_card_is_contactless_question`)}
          description={t(`faqs_${config.operator}.how_can_i_know_if_card_is_contactless_answer`)}
        />
        <Faq number={'11'} title={t(`faqs_${config.operator}.which_cards_can_be_used_question`)} description={t(`faqs_${config.operator}.which_cards_can_be_used_answer`)} />
        <Faq number={'12'} title={t(`faqs_${config.operator}.how_to_use_open_stations_question`)} description={t(`faqs_${config.operator}.how_to_use_open_stations_answer`)} />
        <Faq number={'13'} title={t(`faqs_${config.operator}.how_to_use_closed_stations_question`)} description={t(`faqs_${config.operator}.how_to_use_closed_stations_answer`)} />
        <Faq number={'14'} title={t('custom_faqs.types_of_validators')} description={t('custom_faqs.types_of_validators_flow_description')}>
          <ValidatorTable />
        </Faq>
        <Faq
          number={'15'}
          title={t(`faqs_${config.operator}.can_i_use_bank_card_with_other_contactless_cards_question`)}
          description={t(`faqs_${config.operator}.can_i_use_bank_card_with_other_contactless_cards_answer`)}
        />
        <Faq
          number={'16'}
          title={t(`faqs_${config.operator}.which_validators_have_contactless_question`)}
          description={t(`faqs_${config.operator}.which_validators_have_contactless_answer`)}
        />
        <Faq
          number={'17'}
          title={t(`faqs_${config.operator}.can_i_use_contactless_for_multiple_trips_question`)}
          description={t(`faqs_${config.operator}.can_i_use_contactless_for_multiple_trips_answer`)}
        />
        <Faq number={'18'} title={t(`faqs_${config.operator}.validation_has_failed_question`)} description={t(`faqs_${config.operator}.validation_has_failed_answer`)} />
        <Faq
          number={'19'}
          title={t(`faqs_${config.operator}.charge_if_only_validate_at_exit_question`)}
          description={t(`faqs_${config.operator}.charge_if_only_validate_at_exit_answer`)}
        />
        <Box id='learnMore'>
          <Faq
            number={'20'}
            title={t(`faqs_${config.operator}.where_can_i_check_trips_made_question`)}
            description={t(`faqs_${config.operator}.where_can_i_check_trips_made_answer`)}
          />
        </Box>
        <Faq
          number={'21'}
          title={t(`faqs_${config.operator}.how_to_get_invoice_with_tax_id_question`)}
          description={t(`faqs_${config.operator}.how_to_get_invoice_with_tax_id_answer`)}
        />
        <Faq
          number={'22'}
          title={t(`faqs_${config.operator}.is_portal_registration_mandatory_question`)}
          description={t(`faqs_${config.operator}.is_portal_registration_mandatory_answer`)}
        />
        <Faq
          number={'23'}
          title={t(`faqs_${config.operator}.how_to_prove_contactless_validation_question`)}
          description={t(`faqs_${config.operator}.how_to_prove_contactless_validation_answer`)}
        />
        <Faq
          number={'24'}
          title={t(`faqs_${config.operator}.do_i_have_to_present_card_for_fiscalization_question`)}
          description={t(`faqs_${config.operator}.do_i_have_to_present_card_for_fiscalization_answer`)}
        />
        <Faq
          number={'25'}
          title={t(`faqs_${config.operator}.unable_to_use_contactless_card_question`)}
          description={t(`faqs_${config.operator}.unable_to_use_contactless_card_answer`)}
        />
        <Faq
          number={'26'}
          title={t(`faqs_${config.operator}.card_on_deny_list_use_other_purposes_question`)}
          description={t(`faqs_${config.operator}.card_on_deny_list_use_other_purposes_answer`)}
        />
        <Faq number={'27'} title={t(`faqs_${config.operator}.remove_card_from_deny_list_question`)} description={t(`faqs_${config.operator}.remove_card_from_deny_list_answer`)} />
        <Faq
          number={'28'}
          title={t(`faqs_${config.operator}.how_do_i_access_proof_of_payment_question`)}
          description={t(`faqs_${config.operator}.how_do_i_access_proof_of_payment_answer`)}
        />
        <Faq
          number={'29'}
          title={t(`faqs_${config.operator}.how_is_the_transaction_stated_question`)}
          description={t(`faqs_${config.operator}.how_is_the_transaction_stated_answer`)}
        />
        <Faq
          number={'30'}
          title={t(`faqs_${config.operator}.notifications_regarding_charges_not_recognised_question`)}
          description={t(`faqs_${config.operator}.notifications_regarding_charges_not_recognised_answer`)}
        />
        <Faq number={'31'} title={t(`faqs_${config.operator}.when_is_payment_charged_question`)} description={t(`faqs_${config.operator}.when_is_payment_charged_answer`)} />
        <Faq number={'32'} title={t(`faqs_${config.operator}.is_there_any_extra_charge_question`)} description={t(`faqs_${config.operator}.is_there_any_extra_charge_answer`)} />
        <Faq
          number={'33'}
          title={t(`faqs_${config.operator}.is_this_type_of_payment_safe_question`)}
          description={t(`faqs_${config.operator}.is_this_type_of_payment_safe_answer`)}
        />
        <Faq
          number={'34'}
          title={t(`faqs_${config.operator}.disruptions_journey_charged_question`)}
          description={t(`faqs_${config.operator}.disruptions_journey_charged_answer`)}
        />
        <Faq number={'35'} title={t(`faqs_${config.operator}.comments_and_suggestions_question`)} description={t(`faqs_${config.operator}.comments_and_suggestions_answer`)} />
        <Faq number={'36'} title={t(`faqs_${config.operator}.discount_park_and_ride_question`)} description={t(`faqs_${config.operator}.discount_park_and_ride_answer`)} />
        <Faq
          number={'37'}
          title={t(`faqs_${config.operator}.how_is_my_personal_data_handled_question`)}
          description={t(`faqs_${config.operator}.how_is_my_personal_data_handled_answer`)}
        />
      </Accordion>
    </Flex>
  )
}
