import { Modal, ModalOverlay, ModalContent, Flex, Image, Text, Box, useToast } from '@chakra-ui/react'
import { Button as UbiButton, Column, Row, FormItem, TextInput, CountrySelect } from '@ubirider/pick-component-library'
import { useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { setBillingInfo } from '../../../api/billing'
import closeButton from '../../../assets/cross.svg'
import infoIcon from '../../../assets/information_purple.svg'
import arrowBackIcon from '../../../assets/arrow_left.svg'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'

export function NewBillingModal(props) {
  const { t } = useTranslation()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      profile_name: null,
      name: null,
      country: 'PT',
      tax_id: null,
      address: null,
      postal_code: null,
      city: null,
    },
  })

  const [loading, setLoading] = useState(false)

  const profileName = watch('profile_name')
  const billingName = watch('name')
  const country = watch('country')
  const taxID = watch('tax_id')

  useEffect(() => {
    reset({
      profile_name: null,
      name: null,
      country: 'PT',
      tax_id: null,
      address: null,
      postal_code: null,
      city: null,
    })
    setLoading(null)
  }, [props.isOpen, props.onClose, reset])

  const onSubmit = async values => {
    await setLoading(true)
    try {
      const data = {
        profileName: values.profile_name,
        name: values.name,
        nif: values.tax_id,
        address: values.address ? values.address : '',
        postalCode: values.postal_code ? values.postal_code : '',
        city: values.city ? values.city : '',
        country: values.country,
      }

      console.log(data)

      setBillingInfo(data).then(() => {
        setLoading(true)
        console.log('Entire Document has been updated successfully')
        setLoading(false)
        handleToast()
        props.onClose()
      })
    } catch (error) {
      setLoading(false)
      console.log(error.message)
    }
  }

  const handleToast = () => {
    toastIdRef.current = toast({
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
          <Flex justifyContent='space-between' gap='16px' padding='16px'>
            <Image src={checkWhiteIcon} />
            <Text fontSize='14px' fontWeight='600'>
              {t('billing_information.billing_information_added_toast')}
            </Text>
            <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    })
  }

  const toast = useToast()
  const toastIdRef = useRef()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  function validateTaxID(value) {
    if (country === 'PT' && value.length === 9) return true
    return !(country === 'PT' && value.length !== 9)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960 || props?.blueInfo}
    >
      <ModalOverlay />
      <ModalContent
        margin={{
          sm: props?.blueInfo ? '225px 0 0 0' : '',
          md: props?.blueInfo ? '225px 0 0 0' : '0',
          lg: props?.blueInfo ? '25px' : '0',
          xl: props?.blueInfo ? '25px' : '0',
        }}
        padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}
      >
        <Flex direction='column' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton
                onClick={props.onClose}
                style='ghost'
                type='neutralLight'
                label={t('authentication.back')}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: '0' }}
              />
            </Flex>
          )}
          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('billing_information.new_billing_information')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px' }}>
            {props?.blueInfo && (
              <Flex pb='8px' p='16px' gap='16px' color='white' bg='secondary.100' rounded='4px' direction='row' align='center' border='1px solid #C7D7FE'>
                <Flex>
                  <Image w='20px' src={infoIcon} />
                </Flex>
                <Flex align='flex-start' direction='column' gap='16px'>
                  <Text color='secondary.600' fontWeight='400' fontSize='14px'>
                    {t('travel_history.invoice_change_time_alert')}
                  </Text>
                </Flex>
              </Flex>
            )}

            <Column css={{ gap: '24px' }}>
              <Controller
                name='profile_name'
                control={control}
                render={({ field }) => (
                  <FormItem required label={t('billing_information.billing_profile_name')} error={errors.profile_name}>
                    <TextInput placeholder={t('billing_information.billing_profile_name_placeholder')} {...field} />
                  </FormItem>
                )}
              />

              <Column css={{ gap: '8px' }}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <FormItem required label={t('billing_information.billing_name')} error={errors.name}>
                      <TextInput placeholder={t('billing_information.billing_name_placeholder')} {...field} />
                    </FormItem>
                  )}
                />

                <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} gap='8px'>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field }) => (
                      <FormItem required label={t('billing_information.country')} error={errors.country}>
                        <CountrySelect {...field} />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='tax_id'
                    control={control}
                    rules={{
                      validate: value => validateTaxID(value) || t('authentication.invalid_tax_id'),
                    }}
                    render={({ field }) => (
                      <FormItem required label={t('common.tax_id')} error={errors.tax_id}>
                        <TextInput placeholder={t('common.tax_id')} {...field} />
                      </FormItem>
                    )}
                  />
                </Flex>

                <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} direction='column' gap='8px'>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field }) => (
                      <FormItem required label={t('billing_information.country')} error={errors.country}>
                        <CountrySelect {...field} />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='tax_id'
                    control={control}
                    rules={{
                      validate: value => validateTaxID(value) || t('authentication.invalid_tax_id'),
                    }}
                    render={({ field }) => (
                      <FormItem required label={t('common.tax_id')} error={errors.tax_id}>
                        <TextInput placeholder={t('common.tax_id')} {...field} />
                      </FormItem>
                    )}
                  />
                </Flex>

                <Controller
                  name='address'
                  control={control}
                  render={({ field }) => (
                    <FormItem label={t('billing_information.address')} error={errors.name}>
                      <TextInput placeholder={t('billing_information.address_placeholder')} {...field} />
                    </FormItem>
                  )}
                />

                <Row css={{ gap: '8px' }}>
                  <Controller
                    name='postal_code'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormItem label={t('billing_information.postal_code')} error={errors.postal_code}>
                        <TextInput
                          placeholder={t('billing_information.postal_code')}
                          value={value}
                          onChange={e => {
                            if (!(e?.length > 8 && country === 'PT')) {
                              if (!isNaN(e?.slice(-1)) || e?.slice(-1) === '-') {
                                let inputValue = e
                                console.log(country)
                                if (value?.length > inputValue.length && value?.slice(-1) === '-') {
                                  inputValue = inputValue.slice(0, -1)
                                } else if (inputValue.length === 4 && !inputValue.includes('-')) {
                                  inputValue += '-'
                                }
                                onChange(inputValue)
                              }
                              if (e === null) {
                                onChange(e)
                              }
                            }
                          }}
                        />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='city'
                    control={control}
                    render={({ field }) => (
                      <FormItem label={t('billing_information.city')} error={errors.city}>
                        <TextInput placeholder={t('billing_information.city_placeholder')} {...field} />
                      </FormItem>
                    )}
                  />
                </Row>
              </Column>
            </Column>

            <UbiButton
              submit
              disabled={!(profileName && billingName && country && taxID)}
              loading={loading}
              style='solid'
              type='secondary'
              label={t('common.save')}
              css={{ alignSelf: 'stretch' }}
            />
          </Column>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
