import axios from 'axios'

import { getBFFUrl, getToken } from './requests'

export const handleFinaroPayment = async amount => {
  const token = await getToken()

  const params = {
    BackURL: window.location.origin + '/home',
    FailURL: window.location.origin + '/home/fail',
    SuccessURL: window.location.origin + '/home/success',
    a1: `FERTAGUS${new Date().getTime()}`,
    a4: amount,
    a5: 'EUR',
  }

  const res = await axios.post(`${getBFFUrl()}/payment`, params, {
    headers: {
      'x-access-token': token,
    },
  })

  console.log(res.data)
  return res.data
}

export const retrySwitchIOPayment = async cardToken => {
  const token = await getToken()

  const res = await axios.post(
    `${getBFFUrl()}/retry`,
    {
      token: cardToken,
    },
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  console.log(res.data)
  return res.data
}
