import axios from 'axios'

import { getBFFUrl, getToken } from './requests'

export const changeInvoice = async (cardToken, date, billingInfo) => {
  const token = await getToken()

  const res = await axios.post(
    `${getBFFUrl()}/changeInvoice`,
    {
      cardToken: cardToken,
      date: date,
      billingInfo: {
        vatNumber: billingInfo.nif,
        name: billingInfo.name,
        address: billingInfo.address,
        postalCode: billingInfo.postalCode,
        city: billingInfo.city,
        country: billingInfo.country,
      },
    },
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  //console.log(res)
  return res.data.data
}
