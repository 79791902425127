import { Flex, Divider, Text, Image, Modal, ModalContent, ModalOverlay, useDisclosure, Box, useToast } from '@chakra-ui/react'
import { Button } from '@ubirider/pick-component-library'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RedirectModal } from '../RedirectModal'
import closeButton from '../../../assets/cross.svg'
import plus from '../../../assets/plus_purple.svg'
import visa from '../../../assets/visa.svg'
import mastercard from '../../../assets/mastercard.svg'
import amex from '../../../assets/amex.svg'
import unionpay from '../../../assets/unionpay.svg'
import discover from '../../../assets/discover.svg'
import { retrySwitchIOPayment } from '../../../api/payment'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'
import { SettleErrorModal } from '../SettleErrorModal/SettleErrorModal'
import arrowBackIcon from '../../../assets/arrow_left.svg'

export const SettleAmountModal = props => {
  const { t } = useTranslation()
  const toast = useToast()
  const toastIdRef = useRef()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenError, onOpen: onOpenError, onClose: OnCloseError } = useDisclosure()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const [loading, setLoading] = useState(-1)

  const detectCardBrand = firstSixDigits => {
    switch (true) {
      case /^4/.test(firstSixDigits):
        return visa
      case /^5[1-5]/.test(firstSixDigits):
        return mastercard
      case /^(34|37)/.test(firstSixDigits):
        return amex
      case /^62/.test(firstSixDigits):
        return unionpay
      case /^6/.test(firstSixDigits):
        return discover
      default:
        return null
    }
  }

  const retrySwitchIOTransaction = async (token, index) => {
    setLoading(index)
    try {
      const retry = await retrySwitchIOPayment(token)
      if (retry.message === 'success') {
        props.updateSettledIndexes([...props.settledIndexes, index])
        toastIdRef.current = toast({
          position: 'top',
          duration: 5000,
          isClosable: true,
          render: () => (
            <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
              <Flex justifyContent='space-between' gap='16px' padding='16px'>
                <Image src={checkWhiteIcon} />
                <Text fontSize='14px' fontWeight='600'>
                  {t('travel_history.settle_request_successful_toaster')}
                </Text>
                <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
              </Flex>
            </Box>
          ),
        })
      } else {
        onOpenError()
      }
    } catch (e) {
      console.log(e)
      onOpenError()
    }
    setLoading(-1)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={{ sm: 'full', md: 'full', lg: '', xl: '' }}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius='8px' maxW={{ lg: '600px' }} padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='row' justifyContent='flex-end' alignItems='center' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='24px' align='center'>
          <Flex direction='column' gap='8px' align='center' justify='center' textAlign='center'>
            <Text fontSize={{ sm: '20px', lg: '24px' }} fontWeight='700'>
              {t('travel_history.settle_amount')}
            </Text>
            <Text fontSize={{ sm: '14px', lg: '16px' }}>{t('travel_history.settle_amount_modal_description')}</Text>
          </Flex>

          <Flex direction='column' gap='16px' w='100%' align='flex-start'>
            {props?.trips.map((trip, index) => (
              <>
                <Flex
                  display={{ sm: 'none', lg: 'flex' }}
                  key={`${index}_lg`}
                  gap='16px'
                  padding='8px 16px'
                  align='center'
                  justify='space-between'
                  border='1px solid #EAECF0'
                  borderRadius='4px'
                  boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
                  w='100%'
                  h='56px'
                >
                  <Flex gap='16px' align='center'>
                    <Image w='48px' src={detectCardBrand(trip.card.slice(0, 6))} />
                    <Text fontSize={{ sm: '14px', lg: '16px' }}>{trip.card.match(/.{1,4}/g).join(' ')}</Text>
                  </Flex>
                  <Flex gap='16px' align='center'>
                    <Text fontSize={{ sm: '14px', lg: '14px' }} fontWeight='700'>
                      €{trip.totalAmount.toFixed(2)}
                    </Text>
                    {!props.settledIndexes.includes(index) && (
                      <Button
                        onClick={() => retrySwitchIOTransaction(trip.token, index)}
                        loading={loading === index}
                        type={'primary'}
                        style={'solid'}
                        size={'small'}
                        label={t('travel_history.update_payment_method')}
                        css={{ minWidth: 146 }}
                      />
                    )}
                    {props.settledIndexes.includes(index) && (
                      <Flex
                        width='146px'
                        height='36px'
                        padding='0 16px'
                        justify='center'
                        align='center'
                        border='1px'
                        borderColor='neutral.300'
                        bg='neutral.200'
                        rounded='4px'
                        cursor='default'
                      >
                        <Text fontSize='14px' fontWeight='700' color='neutral.500'>
                          {t('travel_history.registered')}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Flex
                  display={{ sm: 'flex', lg: 'none' }}
                  direction='column'
                  key={`${index}_sm`}
                  gap='16px'
                  padding='8px 16px'
                  align='center'
                  border='1px solid #EAECF0'
                  borderRadius='4px'
                  boxShadow='0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
                  w='100%'
                >
                  <Flex justify='space-between' align='center' w='100%'>
                    <Flex gap='16px' align='center'>
                      <Image w='48px' src={detectCardBrand(trip.card.slice(0, 6))} />
                      <Text fontSize={{ sm: '14px', lg: '16px' }}>{trip.card.match(/.{1,4}/g).join(' ')}</Text>
                    </Flex>
                    <Flex>
                      <Text fontSize={{ sm: '14px', lg: '14px' }} fontWeight='700'>
                        €{trip.totalAmount.toFixed(2)}
                      </Text>
                    </Flex>
                  </Flex>
                  {props.settledIndexes.includes(index) && (
                    <Button
                      onClick={() => retrySwitchIOTransaction(trip.token, index)}
                      loading={loading === index}
                      type={'primary'}
                      style={'solid'}
                      size={'small'}
                      label={t('travel_history.update_payment_method')}
                      css={{ width: '100%' }}
                    />
                  )}
                  {props.settledIndexes.includes(index) && (
                    <Flex
                      width='146px'
                      height='36px'
                      padding='0 16px'
                      justify='center'
                      align='center'
                      border='1px'
                      borderColor='neutral.300'
                      bg='neutral.200'
                      rounded='4px'
                      cursor='default'
                    >
                      <Text fontSize='14px' fontWeight='700' color='neutral.500'>
                        {t('travel_history.registered')}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </>
            ))}
          </Flex>
          <Divider border='1px solid #D0D5DD' />
          <Flex w='100%' display={{ sm: 'none', lg: 'flex' }}>
            <Button
              onClick={onOpen}
              type={'primary'}
              style={'outline'}
              leftIconProps={{ src: plus }}
              label={t('travel_history.settle_all_amounts_new_bank_card')}
              css={{ width: '100%', textAlign: 'left', justifyContent: 'left' }}
            />
          </Flex>
          <Flex w='100%' direction='column' gap='24px' display={{ sm: 'flex', lg: 'none' }}>
            <Text fontSize='18px' fontWeight='600' color='neutral.600' textAlign='center'>
              {t('travel_history.settle_amount_modal_mobile_description')}
            </Text>
            <Button
              onClick={onOpen}
              type={'primary'}
              style={'outline'}
              leftIconProps={{ src: plus }}
              label={t('travel_history.settle_all_amounts_new_bank_card_mobile')}
              css={{ width: '100%', textAlign: 'left', justifyContent: 'left' }}
            />
          </Flex>
        </Flex>
      </ModalContent>
      <RedirectModal trips={props.trips} amount={props.amount} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <SettleErrorModal isOpen={isOpenError} onOpen={onOpenError} onClose={OnCloseError} />
    </Modal>
  )
}
