import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@ubirider/pick-component-library'
import { signOut } from 'firebase/auth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import firebase from '../../../../Firebase'
import config from '../../../../config/configLoader'
import homeIcon from '../../../../assets/home.svg'
import travelIcon from '../../../../assets/menu.svg'
import billingIcon from '../../../../assets/billing.svg'
import notificationIcon from '../../../../assets/path.svg'
import faqsIcon from '../../../../assets/question-mark.svg'
import accountIcon from '../../../../assets/settings.svg'
import supportIcon from '../../../../assets/message-circle.svg'

import { RouteButton } from './components/RouteButton'

export function Menu() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const logout = async () => {
    setLoading(true)
    await signOut(firebase)
  }

  return (
    <Box>
      <Flex
        display={{ base: 'block', sm: 'none', md: 'none', lg: 'block' }}
        gap='24px'
        bg='neutral.100'
        rounded='4px'
        padding='16px'
        align='flex-start'
        minW={{ base: '332px', lg: '332px', xl: '332px' }}
      >
        <Flex gap='24px' direction='column'>
          <Flex direction='column' align='flex-start'>
            <RouteButton icon={homeIcon} label='home.home' route='/home' />

            <RouteButton icon={travelIcon} label='travel_history.travel_history' route='/travel-history' />

            <RouteButton icon={billingIcon} label='billing_information.billing_information' route='/billing-information' />
            {/*
                <RouteButton icon{notificationIcon} label='notifications' route='/notifications' onClose={onClose} />
                */}
          </Flex>

          <Flex direction='column' align='flex-start'>
            <RouteButton icon={accountIcon} label='account.account' route='/account' />

            <RouteButton icon={faqsIcon} label={`faqs_${config.operator}.faqs`} route='/faqs' />

            <RouteButton icon={supportIcon} label='customer_support.customer_support' route='/support' />
          </Flex>

          <Flex>
            <Button
              onClick={() => {
                logout()
              }}
              loading={loading}
              style='solid'
              type='secondary'
              label={t('home.logout_button')}
              css={{ width: '100%' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
