import configMTS from './mts.config'
import configFertagus from './fertagus.config'

const chosenWebsite = process.env.REACT_APP_WEBSITE

let config
if (chosenWebsite === 'fertagus') {
  config = configFertagus
} else if (chosenWebsite === 'mts') {
  config = configMTS
} else {
  config = configFertagus
}

export default config
