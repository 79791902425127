import { Modal, ModalOverlay, ModalContent, Image, Flex } from '@chakra-ui/react'

import mastercardBanner from '../../../assets/mastercard_banner.jpg'
import closeButton from '../../../assets/cross.svg'

export function MastercardModal(props) {
  return (
    <Modal isOpen={props.isOpen} onOpen={props.onOpen} trapFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent maxW={{ sm: '100%', lg: '75%' }} maxH={'calc(100vh - 64px)'} px={'4px'}>
        <Flex justifyContent={'flex-end'} pt={'4px'}>
          <Flex as='button' onClick={props.onClose} type='button'>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Image src={mastercardBanner} />
      </ModalContent>
    </Modal>
  )
}
