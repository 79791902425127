const config = {
  name: 'MTS',
  operator: 'mts',
  agencyID: 107,
  info: {
    email: 'info@mts.pt',
  },
}

export default config
