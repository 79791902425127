import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export default function index(props) {
  return (
    <Flex
      bg='neutral.600'
      rounded='50px'
      p='18px'
      w={{ sm: '40px', md: '40px', lg: '48px', xl: '48px' }}
      h={{ sm: '40px', md: '40px', lg: '48px', xl: '48px' }}
      align='center'
      justify='center'
    >
      <Text color='neutral.25' fontSize={{ sm: '24px', md: '24px', lg: '30px', xl: '30px' }} fontWeight='700'>
        {props.number}
      </Text>
    </Flex>
  )
}
