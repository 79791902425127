import { Box, Flex, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, Divider } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const Notifications = () => {
  const { t } = useTranslation()

  return (
    <Box flex='1'>
      <Flex
        bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }}
        rounded='8px'
        padding={{ sm: '', md: '24px', lg: '24px', xl: '24px' }}
        direction='column'
        gap='28px'
      >
        <Flex gap='16px' direction='column'>
          <Text color='neutral.800' fontWeight='700' fontSize='24px'>
            {t('notifications')}
          </Text>
          <Accordion defaultIndex={[0]} allowToggle flex='1'>
            <AccordionItem marginBlock='16px' rounded='4px'>
              {({ isExpanded }) => (
                <Flex
                  bg={isExpanded ? 'secondary.25' : 'neutral.25'}
                  rounded='4px'
                  border='1px'
                  borderColor={isExpanded ? 'secondary.500' : 'neutral.300'}
                  _hover={{
                    border: '1px',
                    borderColor: 'secondary.500',
                  }}
                  direction='column'
                  css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'}
                >
                  <AccordionButton
                    paddingBlock='18px'
                    _hover={{}}
                    _expanded={{
                      paddingBottom: '0px',
                    }}
                  >
                    <Flex flex='1'>
                      <Flex flex='1' direction='column' gap='8px'>
                        <Flex justifyContent='space-between' align='center'>
                          <Text fontWeight='700' color='neutral.800' fontSize='13px' align='left'>
                            Title
                          </Text>
                        </Flex>
                        <Divider color='neutral.300' />
                        <Flex mt='8px' sx={{ display: isExpanded ? 'none' : 'flex' }}>
                          <Text fontWeight='400' color='neutral.800' fontSize='13px' whiteSpace={'pre-line'}>
                            Description
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex gap='12px'>
                        <AccordionIcon />
                      </Flex>
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pb='16px' pr='48px'>
                    <Flex flex='1' mt='8px'>
                      <Text whiteSpace={'pre-line'}>Full text</Text>
                    </Flex>
                  </AccordionPanel>
                </Flex>
              )}
            </AccordionItem>
          </Accordion>
        </Flex>
      </Flex>
    </Box>
  )
}
