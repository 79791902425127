import { Modal, ModalOverlay, ModalContent, Flex, ModalBody, Text } from '@chakra-ui/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@ubirider/pick-component-library'

import { IconWithinCircle } from '../../Common/IconWithinCircle'
import warningIcon from '../../../assets/alert_yellow.svg'

export function SettleErrorModal(props) {
  const { t } = useTranslation()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      size={{ sm: 'xs', md: '2xl', lg: '2xl', xl: '2xl' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent align='center' justifyContent='center' maxW={{ lg: '600px' }}>
        <Flex
          direction='column'
          align='center'
          justifyContent='center'
          p={{
            sm: '24px 32px',
            md: '24px 32px',
            lg: '48px 56px',
            xl: '48px 56px',
          }}
          gap='16px'
          width='100%'
        >
          <IconWithinCircle color='warning.400' icon={warningIcon} />
          <Text fontWeight='700' fontSize={{ sm: '20px', lg: '24px' }} color='neutral.800'>
            {t('travel_history.settle_request_not_registered_title')}
          </Text>
          <Text fontWeight='400' fontSize='16px' color='neutral.800' align='center'>
            {t('travel_history.settle_request_not_registered_description')}
          </Text>
          <Button type={'neutralLight'} style={'outline'} onClick={props.onClose} label={'Ok'} css={{ width: '100%' }} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
