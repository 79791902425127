import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export function UpdatePaymentBar(props) {
  const { t } = useTranslation()

  if (props.status === 1) {
    return (
      <Flex justify='flex-end' align='center' h='36px' mb='16px'>
        <Text color='warning.600' fontWeight='700' fontSize='13px'>
          {t('travel_history.pending_description')}
        </Text>
      </Flex>
    )
  }
  if (props.status === 2) {
    return (
      <Flex justify='flex-end' align='center' h='36px' mb='16px'>
        <Text color='danger.600' fontWeight='700' fontSize='13px'>
          {t('travel_history.not_accepted_description')}
        </Text>
      </Flex>
    )
  }
}
