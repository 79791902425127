import { Modal, ModalOverlay, ModalContent, Flex, Image, Collapse, Text, Box, useToast } from '@chakra-ui/react'
import { Button as UbiButton, Column, Row, FormItem, TextInput } from '@ubirider/pick-component-library'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'

import { AuthContext } from '../../../AuthProvider'
import closeButton from '../../../assets/cross.svg'
import alertIcon from '../../../assets/alert_red.svg'
import checkIcon from '../../../assets/check_green.svg'
import closeIcon from '../../../assets/cross_red.svg'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'
import arrowBackIcon from '../../../assets/arrow_left.svg'

export function ChangePassModal(props) {
  const { t } = useTranslation()
  const Auth = useContext(AuthContext)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const toast = useToast()
  const toastIdRef = useRef()

  const [alert, setAlert] = useState(false)

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      current_password: null,
      new_password: null,
      confirm_new_password: null,
    },
  })

  useEffect(() => {
    reset({
      current_password: null,
      new_password: null,
      confirm_new_password: null,
    })
    setHasNumber(false)
    setMinCharacters(false)
    setHasUppercase(false)
    setHasLowercase(false)
    setPasswordsMatch(false)
    setLoading(false)
    setAlert(false)
  }, [props.isOpen, props.onClose, reset])

  const [loading, setLoading] = useState(false)
  const [minCharacters, setMinCharacters] = useState(false)
  const [hasUppercase, setHasUppercase] = useState(false)
  const [hasLowercase, setHasLowercase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)

  const password = watch('password')
  const newPassword = watch('new_password')
  const confirmNewPassword = watch('confirm_new_password')

  useEffect(() => {
    checkPassword(newPassword, confirmNewPassword)
  }, [newPassword, confirmNewPassword])

  const checkPassword = (pwd, confirmPwd) => {
    if (pwd?.length >= 8) setMinCharacters(true)
    else setMinCharacters(false)

    if (/[A-Z]/.test(pwd)) setHasUppercase(true)
    else setHasUppercase(false)

    if (/[a-z]/.test(pwd) && pwd !== null) setHasLowercase(true)
    else setHasLowercase(false)

    if (/\d/.test(pwd)) setHasNumber(true)
    else setHasNumber(false)

    if (confirmPwd === pwd && pwd !== null) setPasswordsMatch(true)
    else setPasswordsMatch(false)
  }

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const onSubmit = async values => {
    setAlert(false)
    setLoading(true)
    const user = Auth.user
    const credential = EmailAuthProvider.credential(user.email, values.password)

    try {
      await reauthenticateWithCredential(user, credential)
      await updatePassword(user, values.new_password)

      toastIdRef.current = toast({
        position: 'top',
        duration: 5000,
        isClosable: true,
        render: () => (
          <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
            <Flex justifyContent='space-between' gap='16px' padding='16px'>
              <Image src={checkWhiteIcon} />
              <Text fontSize='14px' fontWeight='600'>
                {t('authentication.password_changed')}
              </Text>
              <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
            </Flex>
          </Box>
        ),
      })
      props.onClose()
    } catch (error) {
      console.log(error.code)
      if (error.code === 'auth/wrong-password') setAlert(true)
      setLoading(false)
    }
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='column' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton
                onClick={props.onClose}
                style='ghost'
                type='neutralLight'
                label={t('authentication.back')}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: '0' }}
              />
            </Flex>
          )}

          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('account.change_password')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px', width: '100%' }}>
            <Column css={{ gap: '8px' }}>
              <Collapse in={alert} animateOpacity marginBlock='8px'>
                <Flex p='16px' gap='16px' color='white' bg='danger.100' rounded='8px' direction='row' align='start'>
                  <Flex pt='5px'>
                    <Image w='44px' src={alertIcon} />
                  </Flex>
                  <Text color='danger.600' fontSize='14'>
                    {t('authentication.password_incorrect')}
                  </Text>
                </Flex>
              </Collapse>

              <Column css={{ gap: '24px' }}>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: t('authentication.required_field') }}
                  render={({ field }) => (
                    <FormItem label={t('authentication.current_password')} error={errors.password}>
                      <TextInput placeholder={t('authentication.current_password')} password {...field} />
                    </FormItem>
                  )}
                />

                <Column css={{ gap: '8px' }}>
                  <Controller
                    name='new_password'
                    control={control}
                    rules={{
                      required: t('authentication.required_field'),
                      validate: value => value !== password || t('authentication.password_must_be_different'),
                    }}
                    render={({ field }) => (
                      <FormItem label={t('authentication.set_new_password')} error={errors.new_password}>
                        <TextInput placeholder={t('authentication.set_new_password')} password {...field} />
                      </FormItem>
                    )}
                  />
                  <Controller
                    name='confirm_new_password'
                    control={control}
                    rules={{ required: t('authentication.required_field') }}
                    render={({ field }) => (
                      <FormItem error={errors.new_password}>
                        <TextInput placeholder={t('authentication.confirm_new_password')} password {...field} />
                      </FormItem>
                    )}
                  />
                </Column>
              </Column>
            </Column>

            <Column css={{ gap: '8px' }}>
              <Text fontSize='14px' fontWeight='600' color='neutral.800'>
                {t('authentication.password_requirements')}
              </Text>
              <Column css={{ gap: '8px' }}>
                <Row css={{ gap: '8px' }}>
                  <Image src={hasNumber ? checkIcon : closeIcon} />
                  <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                    {t('authentication.at_least_8_characters')}
                  </Text>
                </Row>
                <Row css={{ gap: '8px' }}>
                  <Image src={minCharacters ? checkIcon : closeIcon} />
                  <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                    {t('authentication.one_uppercase')}
                  </Text>
                </Row>
                <Row css={{ gap: '8px' }}>
                  <Image src={hasUppercase ? checkIcon : closeIcon} />
                  <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                    {t('authentication.one_lowercase')}
                  </Text>
                </Row>
                <Row css={{ gap: '8px' }}>
                  <Image src={hasLowercase ? checkIcon : closeIcon} />
                  <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                    {t('authentication.one_number')}
                  </Text>
                </Row>
                <Row css={{ gap: '8px' }}>
                  <Image src={passwordsMatch ? checkIcon : closeIcon} />
                  <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                    {t('authentication.type_password_to_confirm')}
                  </Text>
                </Row>
              </Column>
              <UbiButton
                submit
                ref={initialRef}
                loading={loading}
                disabled={!(hasNumber && minCharacters && hasUppercase && hasLowercase && passwordsMatch)}
                style='solid'
                type='secondary'
                label={t('common.save')}
                css={{ alignSelf: 'stretch' }}
              />
            </Column>
          </Column>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
