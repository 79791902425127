import { Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { Button as UbiButton, Text as UbiText, Center, Column, FormItem, Select as UbiSelect, Divider } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { AuthContext } from '../../../AuthProvider'
import { getCards } from '../../../api/cards'
import { getBillingInfo } from '../../../api/billing'
import closeButton from '../../../assets/cross.svg'

export function FiltersModal(props) {
  const { t } = useTranslation()
  const context = useContext(AuthContext)

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: null,
      billing_info: null,
      payment_status: null,
      cards: null,
    },
  })

  const [dateValue, toggleDate] = useState('24_hours')
  const [billingValue, toggleBilling] = useState('all')
  const [paymentValue, togglePayment] = useState('all')
  const [cardsValue, toggleCards] = useState('all')

  const dateOptions = [
    { label: t('filters.last_24_hours'), value: 'last_24h' },
    { label: t('filters.last_48_hours'), value: 'last_48h' },
    { label: t('filters.last_30_days'), value: 'last_30d' },
    { label: t('filters.this_month'), value: 'this_month' },
    { label: t('filters.custom'), value: 'custom' },
  ]

  const billingOptions = [
    { label: t('filters.not_accepted'), value: 'not_accepted' },
    { label: t('filters.pending'), value: 'pending' },
    { label: t('filters.paid'), value: 'paid' },
  ]

  const paymentOptions = [
    { label: t('filters.not_accepted'), value: 'not_accepted' },
    { label: t('filters.pending'), value: 'pending' },
    { label: t('filters.paid'), value: 'paid' },
  ]

  const cardOptions = []

  const [billingInfo, setBillinginfo] = useState([])
  const [cardsInfo, setCards] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const billing = await getBillingInfo()
      const cards = await getCards()
      setBillinginfo(billing)
      setCards(cards)
    }
    fetchData()
    billingInfo.forEach(element => {
      billingOptions.push({ label: element.profileName, value: element.nif })
    })
    cardsInfo.forEach(element => {
      cardOptions.push({
        label: 'Card ' + element.number,
        value: element.number,
      })
    })
    if (context.user) fetchData()
  }, [props.isOpen, context.refresh, context.user])

  const onSubmit = data => {
    console.log(data)
  }

  //const context = useContext(AuthContext);

  //const [filter, setFilter] = useState("");
  //const [param, setParameter] = useState("");

  /*
    function filterChange(target) {
        setFilter(target)
    }

    function paramChange(target) {
        setParameter(target)
    }
    */

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={props.isOpen} onClose={props.onClose} onOpen={props.onOpen}>
      <ModalOverlay />
      <ModalContent mt='67px'>
        <Flex direction='column' marginBlock='14px' marginInline='7px' as='form' onSubmit={handleSubmit(onSubmit)}>
          <Center>
            <ModalHeader flex='2'>{t('filters.new_filter')}</ModalHeader>
            <Flex as='button' m='28px' onClick={props.onClose} type='button'>
              <Image src={closeButton} />
            </Flex>
          </Center>

          <ModalBody pb='8px'>
            <Column css={{ gap: '18px' }}>
              <Column css={{ gap: '8px' }}>
                <UbiText type='textMD' weight='bold'>
                  {t('filters.date')}
                </UbiText>
                <Column css={{ gap: '8px' }}>
                  <Flex padding='10px'>
                    <RadioGroup onChange={toggleDate} value={dateValue}>
                      <Stack gap='8px'>
                        <Radio colorScheme='secondary' value='24_hours'>
                          {t('filters.last_24_hours')}
                        </Radio>
                        <Radio colorScheme='secondary' value='specific_period'>
                          {t('filters.specific_period')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                  <Flex pl='20px'>
                    <Controller
                      name='date'
                      control={control}
                      rules={{ required: t('authentication.required_field') }}
                      render={({ field }) => (
                        <FormItem disabled={dateValue === '24_hours'} error={errors.date}>
                          <UbiSelect items={dateOptions} {...field} />
                        </FormItem>
                      )}
                    />
                  </Flex>
                </Column>
              </Column>
              <Divider />
              <Flex direction='column' gap='8px'>
                <Text color='neutral.800' fontWeight='700'>
                  {t('filters.billing_information')}
                </Text>
                <Flex direction='column' gap='8px'>
                  <Flex padding='10px'>
                    <RadioGroup onChange={toggleBilling} value={billingValue}>
                      <Stack gap='8px'>
                        <Radio colorScheme='secondary' value='all'>
                          {t('filters.all')}
                        </Radio>
                        <Radio colorScheme='secondary' value='view_only'>
                          {t('filters.view_only')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>
                <Flex pl='20px'>
                  <Controller
                    name='billing_info'
                    control={control}
                    rules={{ required: t('authentication.required_field') }}
                    render={({ field: { value, onChange } }) => (
                      <FormItem disabled={billingValue === 'all'} error={errors.billing_info?.message}>
                        <UbiSelect items={billingOptions} value={value} onChange={onChange} />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Flex>
              <Divider />
              <Flex direction='column' gap='8px'>
                <Text color='neutral.800' fontWeight='700'>
                  {t('filters.payment_status')}
                </Text>
                <Flex direction='column' gap='8px'>
                  <Flex padding='10px'>
                    <RadioGroup onChange={togglePayment} value={paymentValue}>
                      <Stack gap='8px'>
                        <Radio colorScheme='secondary' value='all'>
                          {t('filters.all')}
                        </Radio>
                        <Radio colorScheme='secondary' value='view_only'>
                          {t('filters.view_only')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>
                <Flex pl='20px'>
                  <Controller
                    name='payment_status'
                    control={control}
                    rules={{ required: t('authentication.required_field') }}
                    render={({ field: { value, onChange } }) => (
                      <FormItem disabled={paymentValue === 'all'} error={errors.payment_status?.message}>
                        <UbiSelect items={paymentOptions} value={value} onChange={onChange} />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Flex>
              <Divider />
              <Column css={{ gap: '8px' }}>
                <UbiText type='textMD' weight='bold'>
                  {t('filters.contactless_bank_cards')}
                </UbiText>
                <Flex direction='column' gap='8px'>
                  <Flex padding='10px'>
                    <RadioGroup onChange={toggleCards} value={cardsValue}>
                      <Stack gap='8px'>
                        <Radio colorScheme='secondary' value='all'>
                          {t('filters.all')}
                        </Radio>
                        <Radio colorScheme='secondary' value='view_only'>
                          {t('filters.view_only')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>
                <Flex pl='20px'>
                  <Controller
                    name='cards'
                    control={control}
                    rules={{ required: t('authentication.required_field') }}
                    render={({ field: { value, onChange } }) => (
                      <FormItem disabled={cardsValue === 'all'} error={errors.cards?.message}>
                        <UbiSelect items={cardOptions} value={value} onChange={onChange} />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Column>
            </Column>
            {/*
                            <Flex direction="column" gap="16px">
                                <Flex gap="16px">
                                    <Flex flex="6">
                                        <Select
                                            name="filter_item"
                                            id="filter_item"
                                            onChange={e => filterChange(e.target.value)}
                                            width="100%"
                                            placeholder={t("filters.item_id")}
                                        >
                                            <option value="date">{t("filters.date")}</option>
                                            <option value="origin">{t("filters.origin")}</option>
                                            <option value="destination">{t("filters.destination")}</option>
                                            <option value="card_number">{t("filters.card_number")}</option>
                                            <option value="tax_id">{t("filters.tax_id")}</option>
                                            <option value="payment_status">{t("filters.payment_status")}</option>
                                            <option value="amount">{t("filters.amount")}</option>
                                        </Select>
                                    </Flex>
                                    <Flex flex="4">
                                        <Select
                                            name="filter_parameter"
                                            id="filter_parameter"
                                            onChange={e => paramChange(e.target.value)}
                                            isDisabled={!filter}
                                            width="100%"
                                            placeholder={t("filters.item_parameter")}>
                                            { filter === 'date' &&
                                                <>
                                                    <option value="parameter_24h">{t("filters.24_hours")}</option>
                                                    <option value="parameter_48h">{t("filters.48_hours")}</option>
                                                    <option value="parameter_30d">{t("filters.30_days")}</option>
                                                    <option value="parameter_this_month">{t("filters.this_month")}</option>
                                                    <option value="parameter_custom">{t("filters.custom")}</option>
                                                </>
                                            }
                                            { filter !== 'amount' && filter !== 'date' &&
                                                <>
                                                    <option value="parameter_is">{t("filters.is")}</option>
                                                    <option value="parameter_is_not">{t("filters.is_not")}</option>
                                                </>
                                            }
                                            { (filter === 'origin' || filter === 'destination') &&
                                                <option value="parameter_contains">{t("filters.contains")}</option>
                                            }
                                            { filter === 'amount' &&
                                                <>
                                                    <option value="parameter_equal">{t("filters.equal")}</option>
                                                    <option value="parameter_greater">{t("filters.greater")}</option>
                                                    <option value="parameter_less">{t("filters.less")}</option>
                                                    <option value="parameter_not_equal">{t("filters.not_equal")}</option>
                                                </>
                                            }
                                        </Select>
                                    </Flex>
                                </Flex>
                                { (filter === 'origin' || filter === 'destination' || filter === 'amount') &&
                                    <InputGroup>
                                        <Input
                                            isDisabled={!param}
                                            placeholder={filter === 'amount' ? 'filter.insert_amount' : 'filter.insert_stop'}
                                        />
                                        { filter === 'amount' &&
                                            <InputRightElement
                                                pointerEvents='none'
                                                color='neutral.600'
                                                fontSize='1.2em'
                                                children='€'
                                            /> }
                                    </InputGroup>
                                }
                                { filter === 'date' && param === 'custom' &&
                                    <>
                                        <Flex direction="column">
                                            <InputGroup>

                                            </InputGroup>
                                        </Flex>
                                        <Flex direction="column">
                                            <InputGroup>

                                            </InputGroup>
                                        </Flex>
                                    </>
                                }
                            </Flex> */}
          </ModalBody>

          <ModalFooter>
            <UbiButton submit style='solid' type='secondary' label={t('filters.add_filter')} css={{ width: '428px' }} />
          </ModalFooter>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
