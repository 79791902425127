import { Divider, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import editIcon from '../../../../../../assets/edit_purple.svg'
import filterIcon from '../../../../../../assets/filters_purple.svg'

import { PaymentStatus } from './components/PaymentStatus'
import { UpdatePaymentBar } from './components/UpdatePaymentBar'
import { BlueSection } from './components/BlueSection'
import Invoice from './components/Invoice/Invoice'
import { VatTag } from './components/VatType/VatTag'

export function Trip({ billing, trip, onTrip, isChecked, onChange, onEdit }) {
  const { t } = useTranslation()

  return (
    <Accordion allowToggle onChange={onTrip}>
      <AccordionItem marginBlock='8px' rounded='4px'>
        {({ isExpanded }) => (
          <Flex
            bg={isExpanded || isChecked ? 'secondary.25' : 'neutral.25'}
            rounded='4px'
            border='1px'
            borderColor={isExpanded || isChecked ? 'secondary.500' : 'neutral.300'}
            _hover={{
              border: '1px',
              borderColor: 'secondary.500',
            }}
            direction='column'
            css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'}
          >
            <AccordionButton
              paddingBlock='18px'
              _hover={{}}
              _expanded={{
                paddingBottom: '0px',
              }}
            >
              {/*
              <Checkbox
                  display={{ sm: "none", md: "block", lg: "block", xl: "block" }}
                  size="lg"
                  colorScheme="secondary"
                  isChecked={isChecked}
                  onChange={onChange}
                  pl="8px"
              />
              */}
              <Flex display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                <PaymentStatus code={trip.status.code} isExpanded={isExpanded} />
              </Flex>
              <Flex flex='1' pl='23px' justifyContent='space-between' alignItems='center'>
                <Text color='neutral.800' fontSize='13px'>
                  {DateTime.fromMillis(trip.date, {
                    zone: 'utc',
                  })
                    .setZone('Europe/Lisbon')
                    .toFormat('dd/MM/yyyy')}
                </Text>
                <Text display={isExpanded ? { sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } : 'flex'} color='neutral.800' fontSize='13px'>
                  {DateTime.fromMillis(trip.date, {
                    zone: 'utc',
                  })
                    .setZone('Europe/Lisbon')
                    .toFormat('HH:mm')}
                </Text>
                <Text display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} color='neutral.800' fontSize='13px'>
                  {trip.cardPAN.match(/.{1,4}/g).join(' ')}
                </Text>

                <VatTag text={trip.invoices[0]} name={billing} />

                <Text color='neutral.800' fontSize='13px' fontWeight='700'>
                  {(Math.round(trip.amount * 100) / 100).toFixed(2)}€
                </Text>
                <Flex display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} align='center' gap='13px'>
                  <PaymentStatus code={trip.status.code} />
                  <AccordionIcon />
                </Flex>
                <Flex display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }} align='center' gap='13px'>
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
            <AccordionPanel pl={{ sm: '', md: '64px', lg: '64px', xl: '64px' }} pr={{ sm: '', md: '48px', lg: '48px', xl: '48px' }}>
              <Divider color='neutral.300' />
              <Flex pt='8px'>
                <Flex direction='column' flex='1'>
                  <Flex marginInline='8px' direction='column' flex='1' display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}>
                    <UpdatePaymentBar status={trip.status.code} amount={trip.amount} />
                    <Text fontSize='14px' fontWeight='700'>
                      {t('travel_history.selected_trip')}
                    </Text>

                    <Flex justifyContent='space-between' gap='8px'>
                      <Flex gap='8px'>
                        <Text fontSize='13px'>{t('travel_history.origin_destination')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {trip.plannedOrigin} - {trip.plannedDestination}
                        </Text>
                      </Flex>
                      <Flex gap='8px'>
                        <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                      <Flex gap='8px'>
                        <Text fontSize='13px'>{t('travel_history.total')}</Text>
                        <Text fontSize='13px' fontWeight='600'>
                          {isNaN(trip.plannedAmount) ? '- ' : (Math.round(trip.plannedAmount * 100) / 100).toFixed(2)}€
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex gap='8px' padding='0 8px' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                    <UpdatePaymentBar status={trip.status.code} amount={trip.amount} />
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('travel_history.date')}
                      </Text>
                      <Flex gap='24px'>
                        <Text color='neutral.800' fontSize='13px'>
                          {DateTime.fromMillis(trip.date, {
                            zone: 'utc',
                          })
                            .setZone('Europe/Lisbon')
                            .toFormat('dd/MM/yyyy')}
                        </Text>
                        <Text color='neutral.800' fontSize='13px'>
                          {DateTime.fromMillis(trip.date, {
                            zone: 'utc',
                          })
                            .setZone('Europe/Lisbon')
                            .toFormat('HH:mm')}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='14px' fontWeight='700'>
                        {t('common.card_number')}
                      </Text>
                      <Text fontSize='13px'>{trip.cardPAN.match(/.{1,4}/g).join(' ')}</Text>
                    </Flex>
                  </Flex>

                  <BlueSection origin={trip.origin} destination={trip.destination} amount={trip.amount} />

                  <Flex justifyContent='space-between' gap='8px' padding='8px' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
                    <Text fontSize='14px' fontWeight='700'>
                      {t('travel_history.selected_trip')}
                    </Text>
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='13px'>{t('travel_history.origin_destination')}</Text>
                      <Text fontSize='13px' fontWeight='600'>
                        {trip.plannedOrigin} - {trip.plannedDestination}
                      </Text>
                    </Flex>
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='13px'>{t('travel_history.discount')}</Text>
                      <Text fontSize='13px' fontWeight='600'>
                        {(Math.round(trip.discount * 100) / 100).toFixed(2)}€
                      </Text>
                    </Flex>
                    <Flex gap='4px' direction='column'>
                      <Text fontSize='13px'>{t('travel_history.total')}</Text>
                      <Text fontSize='13px' fontWeight='600'>
                        {isNaN(trip.plannedAmount) ? '- ' : (Math.round(trip.plannedAmount * 100) / 100).toFixed(2)}€
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex gap='4px' marginInline='8px' align='center'>
                    <Text fontSize='13px' fontWeight='600'>
                      {t('common.tax_id')}
                    </Text>
                    <Text fontSize='13px' fontWeight='400'>
                      {trip?.invoices[0]?.vatNumber && trip?.invoices[0]?.vatNumber !== '-'
                        ? trip.invoices[0].vatNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
                        : '999 999 999'}
                    </Text>
                    <UbiButton
                      disabled={trip?.invoices[0]?.invoiceName === undefined && trip?.invoiceName === undefined}
                      onClick={onEdit}
                      size='small'
                      style='ghost'
                      type='primary'
                      leftIconProps={{ src: editIcon }}
                    />
                  </Flex>

                  <Flex padding='8px 0 8px 8px' direction='column' gap='8px'>
                    <Flex alignItems='flex-start' gap='8px'>
                      <Text fontSize='13px' fontWeight='600'>
                        {t('travel_history.documents')}
                      </Text>
                    </Flex>
                    <Divider border='1px solid #D0D5DD' />
                    <Flex
                      alignItems='flex-start'
                      display={{
                        sm: 'none',
                        md: 'flex',
                        lg: 'flex',
                        xl: 'flex',
                      }}
                      flex='1'
                    >
                      <Flex flex='2'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.date')}
                        </Text>
                      </Flex>
                      <Flex flex='3'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.invoice')}
                        </Text>
                      </Flex>
                      <Flex flex='2'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.status')}
                        </Text>
                      </Flex>
                      <Flex flex='3'>
                        <Text fontSize='13px' color='#1D2939'>
                          {t('travel_history.credit_note')}
                        </Text>
                      </Flex>
                    </Flex>
                    {trip.invoices.map((invoice, i) => (
                      <Invoice key={i} invoice={invoice} />
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>
    </Accordion>
  )
}
