import { Modal, ModalOverlay, ModalContent, ModalHeader, Flex, Image, ModalBody, ModalFooter, Text, Collapse, Box, useToast } from '@chakra-ui/react'
import { Button as UbiButton, Center, FormItem, TextInput } from '@ubirider/pick-component-library'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { EmailAuthProvider, reauthenticateWithCredential, deleteUser } from 'firebase/auth'

import { AuthContext } from '../../../AuthProvider'
import closeButton from '../../../assets/cross.svg'
import alertIcon from '../../../assets/alert_red.svg'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'
import arrowBackIcon from '../../../assets/arrow_left.svg'

export function ReauthDelModal(props) {
  const { t } = useTranslation()
  const Auth = useContext(AuthContext)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const toast = useToast()
  const toastIdRef = useRef()
  console.log(Auth)

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { password: null } })

  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reset({
      password: null,
    })
  }, [props.isOpen, reset])

  const handleDeleted = () => {
    toastIdRef.current = toast({
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
          <Flex justifyContent='space-between' gap='16px' padding='16px'>
            <Image src={checkWhiteIcon} />
            <Text fontSize='14px' fontWeight='600'>
              {t('account.account_deleted')}
            </Text>
            <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    })
    props.onOpenSM()
    props.onClose()
  }

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const onSubmit = async values => {
    setLoading(true)
    const user = Auth.user
    const credential = EmailAuthProvider.credential(user.email, values.password)
    try {
      await reauthenticateWithCredential(user, credential)
      await deleteUser(user).then(() => {
        handleDeleted()
      })
    } catch (error) {
      console.log(error)
      if (error.code === 'auth/wrong-password') setAlert(true)
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='column' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }} as='form' onSubmit={handleSubmit(onSubmit)}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton
                onClick={props.onClose}
                style='ghost'
                type='neutralLight'
                label={t('authentication.back')}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: '0' }}
              />
            </Flex>
          )}

          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('account.confirmation')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Flex direction='column' gap='24px'>
            <Collapse in={alert} animateOpacity marginBlock='8px'>
              <Flex p='16px' gap='16px' color='white' bg='danger.100' rounded='8px' direction='row' align='start'>
                <Flex pt='5px'>
                  <Image src={alertIcon} />
                </Flex>
                <Text color='danger.600' fontSize='14'>
                  {t('authentication.password_incorrect')}
                </Text>
              </Flex>
            </Collapse>
            <Text color='neutral.600'>{t('account.delete_account_confirmation_description')}</Text>
            <Controller
              name='password'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem error={errors.password}>
                  <TextInput placeholder={t('authentication.your_password')} password {...field} />
                </FormItem>
              )}
            />
          </Flex>

          <UbiButton submit loading={loading} style='solid' type='secondary' label={t('account.confirm_delete_account')} css={{ alignSelf: 'stretch' }} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
