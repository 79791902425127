const config = {
  name: 'Fertagus',
  operator: 'fertagus',
  agencyID: 1,
  info: {
    email: 'info@fertagus.pt',
  },
}

export default config
