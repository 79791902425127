import { useContext, useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'

import { TravelHistoryTable } from '../Home/components/TravelHistoryTable'
import { getTrips } from '../../api/user'
import { getBillingInfo } from '../../api/billing'
import { AuthContext } from '../../AuthProvider'
import { PaymentDueBar } from '../Home/components/PaymentDueBar'

function TravelHistoryPage() {
  const context = useContext(AuthContext)
  const [trips, setTrips] = useState([])
  const [billing, setBilling] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const fetchedTrips = await getTrips()
      const fetchedBilling = await getBillingInfo()

      setTrips(fetchedTrips)
      setBilling(fetchedBilling)
      setLoading(false)
    }

    if (context.user) fetchData()
  }, [context.user])

  return (
    <Flex direction='column' gap='20px' flex='1'>
      <PaymentDueBar trips={trips} />
      <TravelHistoryTable trips={trips} loading={loading} billing={billing} />
    </Flex>
  )
}

export default TravelHistoryPage
