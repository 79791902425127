import { Center, Collapse, Flex, Image, List, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { NewBillingModal } from '../../../Modals/NewBillingModal/NewBillingModal'
import { SelectBillingModal } from '../../../Modals/SelectBillingModal/SelectBillingModal'
import { FiltersModal } from '../../../Modals/FiltersModal/FiltersModal'

//import { TableBar } from "./components/TableBar";
//import { SorterTab } from "./components/SorterTab";

import trainForbidden from '../../../../assets/train_forbidden.svg'
import informationIcon from '../../../../assets/information_purple.svg'
import filterIcon from '../../../../assets/filters_purple.svg'
import { getBillingInfo } from '../../../../api/billing'
import { getCards } from '../../../../api/cards'
import { getTrips } from '../../../../api/user'

import { Trip } from './components/Trip'

export const TravelHistoryTable = ({ home, trips, billing, loading }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  /*  trips = [
    {
      token: "123abc",
      date: 1584369396000,
      plannedAmount: 200,
      amount: 150,
      cardPAN: "123***56",
      discount: 10,
      invoices: [
        {
          date: 1584369396000,
          invoiceName: "GG123",
          invoiceURL: "google.com",
          vatNumber: "999999999",
          status: "issued",
        },
      ],
      plannedOrigin: "Origin",
      origin: "originReal",
      plannedDestination: "Destination",
      destination: "destinationReal",
      status: { code: 500, message: "" },
    },
  ];*/

  const [modalTrips, setModalTrips] = useState([])
  const [sortedTrips, setSortedTrips] = useState(trips)
  // const [isAllChecked, setIsAllChecked] = useToggle(false);
  const [checkedTrips, setCheckedTrips] = useState([])
  // const allChecked = checkedTrips.length === trips.length && trips.length > 0;
  // const isIndeterminate = checkedTrips.some(Boolean) && !allChecked;
  const [alert, setAlert] = useState(false)
  const [alert2, setAlert2] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isOpen: isOpenSB, onOpen: onOpenSB, onClose: onCloseSB } = useDisclosure()

  const { isOpen: isOpenFilters, onOpen: onOpenFilters, onClose: onCloseFilters } = useDisclosure()

  useEffect(() => {
    setSortedTrips(trips)
  }, [trips])

  useEffect(() => {
    const fetchData = async () => {
      const fetchedTrips = await getTrips()
      setSortedTrips(fetchedTrips.slice(0, 5))
    }

    fetchData()
  }, [isOpenSB, onCloseSB])

  const handleCheckTrip = newTrip => {
    if (!checkedTrips.includes(newTrip) && checkedTrips.length < 5) {
      setCheckedTrips(checkedTrips => [...checkedTrips, newTrip])
      if (checkedTrips.length > 0) {
        setAlert2(true)
      } else {
        setAlert2(false)
      }
      if (checkedTrips.length >= 0) {
        setAlert(true)
      } else {
        setAlert(false)
      }
    } else {
      if (checkedTrips.length <= 1) {
        setAlert(false)
      }
      if (checkedTrips.length <= 2) {
        setAlert2(false)
      }
      setCheckedTrips(checkedTrips =>
        checkedTrips.filter(trip => {
          return trip !== newTrip
        })
      )
    }
  }

  // const handleCheckAllTrips = () => {
  //   if (isAllChecked === false) {
  //     setCheckedTrips(trips);
  //     setIsAllChecked();
  //   } else {
  //     setCheckedTrips([]);
  //     setIsAllChecked();
  //   }
  // };

  const Sorter = (property, direction) => {
    if (direction === 'up') {
      if (property === 'date') {
        setSortedTrips([...trips].sort((a, b) => b.date - a.date))
      }
      if (property === 'time') {
        setSortedTrips([...trips].sort((a, b) => new Date(b.date * 1000).getTime() - new Date(a.date * 1000).getTime()))
      }
      if (property === 'card') {
        setSortedTrips([...trips].sort((a, b) => b.cardPAN - a.cardPAN))
      }
      if (property === 'nif') {
        setSortedTrips([...trips].sort((a, b) => b.invoices[0].vatNumber - a.invoices[0].vatNumber))
      }
      if (property === 'amount') {
        setSortedTrips([...trips].sort((a, b) => b.amount - a.amount))
      }
      if (property === 'status') {
        setSortedTrips([...trips].sort((a, b) => b.status.code - a.status.code))
      }
    }
    if (direction === 'down') {
      if (property === 'date') {
        setSortedTrips([...trips].sort((a, b) => a.date - b.date))
      }
      if (property === 'time') {
        setSortedTrips([...trips].sort((a, b) => new Date(a.date * 1000).getTime() - new Date(b.date * 1000).getTime()))
      }
      if (property === 'card') {
        setSortedTrips([...trips].sort((a, b) => a.cardPAN - b.cardPAN))
      }
      if (property === 'nif') {
        setSortedTrips([...trips].sort((a, b) => a.invoices[0].vatNumber - b.invoices[0].vatNumber))
      }
      if (property === 'amount') {
        setSortedTrips([...trips].sort((a, b) => a.amount - b.amount))
      }
      if (property === 'status') {
        setSortedTrips([...trips].sort((a, b) => a.status.code - b.status.code))
      }
    }
  }

  const handleOpenModal = trips => {
    setModalTrips(trips)
    if (billing.length > 0) {
      onOpenSB()
    } else {
      onOpen()
    }
  }

  const handleCloseModal = () => {
    onClose()
    if (billing.length > 0) {
      onOpenSB()
    }
  }

  return (
    <Flex direction='column' gap='32px' flex='1'>
      <Flex
        bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }}
        padding={{ sm: '0', md: '24px', lg: '24px', xl: '24px' }}
        rounded='8px'
        gap='24px'
        direction='column'
      >
        <Flex gap='28px' direction='column' flex='1'>
          <Flex flex='1' align='center' justify='space-between'>
            <Text fontWeight='700' fontSize='24px'>
              {t('travel_history.travel_history')}
            </Text>
            {/*trips != null && trips.length > 0 ? (
              <Flex display={{ sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                <UbiButton
                  onClick={onOpenFilters}
                  style='outline'
                  type='primary'
                  size='small'
                  disabled
                  leftIconProps={{ src: filterIcon }}
                  label={t('travel_history.filters')}
                />
              </Flex>
            ) : (
              <></>
            )*/}
          </Flex>

          {loading ? (
            <Center minH='240px'>
              <Spinner thickness='6px' color='neutral.500' size='xl' />
            </Center>
          ) : trips != null && trips.length > 0 ? (
            <>
              <Text fontSize='14px' color='neutral.800'>
                {t('travel_history.travel_history_description')}
              </Text>
              {/*<Flex display={{ sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                <UbiButton
                  onClick={onOpenFilters}
                  style='outline'
                  type='primary'
                  size='small'
                  disabled
                  leftIconProps={{ src: filterIcon }}
                  label={t('travel_history.filters')}
                />
              </Flex>*/}
              <Flex direction='column' flex='1'>
                <Flex p='18.5px' gap='16px' color='white' border='1px' borderColor='secondary.200' bg='secondary.100' rounded='4px' direction='row' align='flex-start' mb='32px'>
                  <Image w='20px' src={informationIcon} />
                  <Text color='secondary.600' fontSize='14'>
                    {t('travel_history.message_trips_charged_tomorrow')}
                  </Text>
                </Flex>
                <Collapse in={alert2} animateOpacity>
                  <Flex direction='row' justifyContent='flex-end' gap='16px'>
                    <UbiButton onClick={() => handleOpenModal(checkedTrips)} loading={loading} style='outline' type='primary' label={t('travel_history.change_tax_id')} />

                    <UbiButton loading={loading} style='solid' type='primary' label={t('travel_history.download_invoice')} />
                  </Flex>
                </Collapse>
                {/*
                <Flex
                  gap="23px"
                  justifyContent="space-between"
                  pl="25px"
                  height="48px"
                >
                  <Flex
                    // display={isIndeterminate || allChecked ? "none" : "flex"}
                    direction="row"
                    gap="23px"
                    justifyContent="space-between"
                    flex="1"
                    align="center"
                    marginRight="48px"
                  >
                    <Flex />

                    {/*<Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("date")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("date", "up");
                        }}
                        onClickDown={() => {
                          Sorter("date", "down");
                        }}
                      />
                    </Flex>

                    <Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("time")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("time", "up");
                        }}
                        onClickDown={() => {
                          Sorter("time", "down");
                        }}
                      />
                    </Flex>

                    <Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("card")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("card", "up");
                        }}
                        onClickDown={() => {
                          Sorter("card", "down");
                        }}
                      />
                    </Flex>

                    <Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("common.tax_id")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("nif", "up");
                        }}
                        onClickDown={() => {
                          Sorter("nif", "down");
                        }}
                      />
                    </Flex>

                    <Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("amount")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("amount", "up");
                        }}
                        onClickDown={() => {
                          Sorter("amount", "down");
                        }}
                      />
                    </Flex>
                    <Flex gap="8px">
                      <Text color="neutral.400" fontSize="13px" mr="0px">
                        {t("payment_status")}
                      </Text>
                      <SorterTab
                        onClickUp={() => {
                          Sorter("status", "up");
                        }}
                        onClickDown={() => {
                          Sorter("status", "down");
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    display={isIndeterminate || allChecked ? "flex" : "none"}
                    justifyContent="space-between"
                    flex="1"
                    align="center"
                  >
                    <Flex />
                    <TableBar
                      trips={checkedTrips}
                      onClick={() => handleOpenModal(checkedTrips)}
                    />
                  </Flex>
                </Flex>*/}

                <List>
                  {sortedTrips.map((trip, i) => (
                    <Trip
                      billing={billing}
                      trip={trip}
                      key={i}
                      isChecked={checkedTrips.includes(trip)}
                      onChange={() => handleCheckTrip(trip)}
                      onEdit={() => handleOpenModal([trip])}
                    />
                  ))}
                </List>
              </Flex>
            </>
          ) : (
            <Flex
              direction='column'
              my={{ sm: '', md: '48px', lg: '48px', xl: '48px' }}
              mx={{ sm: '', md: '96px', lg: '96px', xl: '96px' }}
              justifyContent='center'
              alignItems='center'
              textAlign='center'
              gap='24px'
            >
              <Image src={trainForbidden} height='112px' />
              <Text fontSize='14px' color='neutral.800' textAlign='center'>
                {t('travel_history.travel_history_empty_state')}
              </Text>
              <UbiButton
                onClick={() => {
                  navigate('/faqs', { state: true })
                }}
                loading={loading}
                style='solid'
                type='primary'
                label={t('travel_history.know_more')}
              />
            </Flex>
          )}
        </Flex>

        {home && (
          <Flex
            align='center'
            justify='space-between'
            display={loading || trips == null || trips.length === 0 ? 'none' : 'flex'}
            padding={{ sm: '', md: '', lg: '0 16px', xl: '0 16px' }}
            direction={{ sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            gap={{ sm: '12px', md: '12px', lg: '48px', xl: '48px' }}
          >
            <Text alignSelf={{ sm: 'center', md: 'center', lg: '', xl: '' }} color='neutral.600' fontWeight='400' fontSize='14px'>
              {t('travel_history.displaying_latest_trips')}
            </Text>
            <Flex
              justify={{
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
                xl: 'flex-start',
              }}
            >
              <UbiButton
                onClick={() => {
                  navigate('/travel-history')
                }}
                size='small'
                style='ghost'
                type='primary'
                label={t('travel_history.see_all_trips')}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      <FiltersModal onOpen={onOpenFilters} onClose={onCloseFilters} isOpen={isOpenFilters} />
      <NewBillingModal onOpen={onOpen} onClose={handleCloseModal} isOpen={isOpen} blueInfo />
      <SelectBillingModal onOpen={onOpenSB} onClose={onCloseSB} isOpen={isOpenSB} onOpenBM={onOpen} trips={modalTrips} />
    </Flex>
  )
}
