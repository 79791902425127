import { Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Button as UbiButton, ButtonDropdown, setLanguage } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import i18next from 'i18next'

import train from '../../assets/train_waiting.svg'
import globeIcon from '../../assets/globe.svg'
import { MastercardModal } from '../Modals/MastercardModal/MastercardModal'

import { CircleView } from './CircleView'

export default function Landing() {
  const { t } = useTranslation()

  const [login, getLogin] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()

  function toggleForm() {
    getLogin(true)
  }

  useEffect(() => {
    onOpen()
  }, [])

  function handleLanguage(lang) {
    localStorage.setItem('Language', lang)
    i18next.changeLanguage(lang)
    setLanguage(lang)
  }

  const langOptions = [
    {
      label: t('home.english_language'),
      value: 'EN',
      selected: 'EN' === i18next.language,
      onClick: () => handleLanguage('EN'),
    },
    {
      label: t('home.portuguese_language'),
      value: 'PT',
      selected: 'PT' === i18next.language,
      onClick: () => handleLanguage('PT'),
    },
  ]

  return (
    <Flex
      as='main'
      direction='column'
      mt={{ sm: '52px', lg: '67px' }}
      flex='1'
      minHeight='calc(100vh - 67px)'
      bg={login ? 'none' : 'neutral.100'}
      padding={
        login
          ? { sm: '0', md: '0', lg: '60px', xl: '80px' }
          : {
              sm: '16px 24px 48px 24px',
              md: '16px 24px 48px 24px',
              lg: '16px 80px 48px 80px',
              xl: '16px 130px 48px 130px',
            }
      }
    >
      {!login && (
        <Flex justify='flex-end'>
          <ButtonDropdown
            usePortal={false}
            buttonProps={{
              type: 'neutralDark',
              style: 'ghost',
              leftIconProps: { src: globeIcon },
            }}
            cells={langOptions}
            placement={'bottom-end'}
            dropdownWidth={200}
            background={'white'}
            css={{
              borderRadius: 4,
              boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Flex>
      )}
      <Flex gap={{ sm: '56px', md: '56px', lg: '80px', xl: '80px' }} rounded='8px' mt='16px' align='flex-start' flex='1'>
        <Flex
          direction='column'
          align='center'
          gap={{ sm: '56px', md: '56px', lg: '36px', xl: '36px' }}
          flex={{ sm: '1', md: '1', lg: '5', xl: '6' }}
          display={login ? { sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } : 'flex'}
        >
          <Flex gap='8px' direction='column' align='center' justify='center' textAlign='center'>
            <Text fontSize={{ sm: '24px', md: '24px', lg: '30px', xl: '36px' }} fontWeight='700'>
              {t('authentication.your_contactless_portal_title')}
            </Text>
            <Text fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '24px' }} fontWeight='400'>
              {t('authentication.your_contactless_portal_subtitle')}
            </Text>
            <Image src={train} w={{ sm: '260px', md: '260px', lg: '270px', xl: '270px' }} />
          </Flex>

          <Flex alignSelf='stretch' display={{ sm: 'block', md: 'block', lg: 'none', xl: 'none' }}>
            <UbiButton onClick={toggleForm} style='solid' type='secondary' label={t('common.login')} css={{ width: '100%' }} />
          </Flex>

          <Flex direction='column' align='stretch' gap='48px'>
            <Text align='center' fontSize={{ sm: '24px', md: '24px', lg: '30px', xl: '30px' }} fontWeight='600'>
              {t('authentication.how_to_get_started')}
            </Text>

            <Flex direction='column' gap='36px'>
              <Flex gap='24px' align='center'>
                <CircleView number='1' />
                <Text fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='600' color='neutral.500'>
                  {t('authentication.enter_contactless_card')}
                </Text>
              </Flex>

              <Flex gap='24px' align='center'>
                <CircleView number='2' />
                <Text fontSize={{ sm: '16px', md: '16px', lg: '20px', xl: '20px' }} fontWeight='600' color='neutral.500'>
                  {t('authentication.check_payment_travel_history')}
                </Text>
              </Flex>
              <Flex gap='24px' align='center'>
                <CircleView number='3' />
                <Flex direction='column'>
                  <Text
                    fontSize={{
                      sm: '16px',
                      md: '16px',
                      lg: '20px',
                      xl: '20px',
                    }}
                    fontWeight='600'
                    color='neutral.500'
                  >
                    {t('authentication.add_billing_info_title')}
                  </Text>
                  <Text fontSize='14px' fontWeight='400' color='neutral.500'>
                    {t('authentication.add_billing_info_subtitle')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex display={login ? 'block' : { sm: 'none', md: 'none', lg: 'block', xl: 'block' }} flex={{ sm: '1', md: '1', lg: '5', xl: '4' }}>
          <Outlet />
        </Flex>
      </Flex>

      <MastercardModal onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
