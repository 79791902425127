import { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'

import firebase from './Firebase.js'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [refresh, setRefresh] = useState(true)
  const [travelRefresh, setTravelRefresh] = useState([])

  function usePosts() {
    const context = useContext(AuthContext)

    function postQuestionHandler(newPost) {
      // ...
      context.setTravelRefresh(travel => [...travelRefresh, travel])
    }

    return [context.travelRefresh, postQuestionHandler]
  }

  useEffect(() => {
    let isMounted = true
    onAuthStateChanged(firebase, currentUser => {
      if (isMounted) setUser(currentUser)
      console.log('user is authenticated')
    })
    return () => {
      isMounted = false
    }
  }, [])

  return <AuthContext.Provider value={{ setUser, user, refresh, setRefresh, travelRefresh, setTravelRefresh }}>{children}</AuthContext.Provider>
}
